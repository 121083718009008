import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import {Helmet} from "react-helmet";
import "../styles.scss";
import GridProdutos from '../components/Produtos/GridProdutos';
import HeroVideo from '../components/GeneralComponents/HeroVideo';
import { graphql } from 'gatsby';

const ProdutosPage = ({data}) => {
    return(
        <>
            <Helmet>
                <html lang="pt-BR" amp />
                <meta charSet="UTF-8" />
                <title>Cia Ibérica - Produtos</title>
                <link rel="canonical" href="https://www.ciaiberica.com.br/produtos/" />
                <meta name="robots" content="index,follow,max-snippet: -1,max-image-preview:large" />
                <meta property="og:locale" content="pt-BR" />
                <meta property="og:title" content="Cia Ibérica - Produtos" />
                <meta property="og:description" content="Confira os produtos da Cia. Ibérica, empresa brasileira e com tradição no mercado." />
                <meta property="og:url" content="https://www.ciaiberica.com.br/produtos/" />
                <meta property="og:sitename" content="Cia Ibérica - Produtos" />
                <meta property="article:publisher" content="https://facebook.com/ibericacondutoreseletricosltda" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            </Helmet>
            <Header />
            <HeroVideo props={data} />
            <div className="container mt-3 mb-3 text-center">
                <h2>Produtos</h2>
            </div>
            <GridProdutos />
            <Footer />
        </>
    )
}

export const query = graphql`
    query ProdutosQuery {
        video: contentfulAsset(title: {eq: "produtos"}) {
            file {
              url
              contentType
            }
        }
        poster: contentfulAsset(title: {eq: "produtos-poster"}) {
            file {
              url
              contentType
            }
        }
    }
`

export default ProdutosPage