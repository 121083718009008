import React, {Component} from 'react';
import {graphql, StaticQuery, Link} from 'gatsby';
import {GatsbyImage} from "gatsby-plugin-image"

class GridProdutos extends Component {
    render() {
        return (
            <div className="container iberica-grid-produto-container">
                <div className="row">
                    {
                        this.props.data.produtos.edges.slice().map(({node: produto, index}) => {
                            return (
                                <div className="col-sm-6 iberica-grid-produto-col" key={produto.urlProduto}>
                                    <div className="card iberica-grid-produto-card">
                                        <div className="card-img-top">
                                            <GatsbyImage className="image iberica-grid-produto-photo" style={{maxHeight: "600px"}} objectFit="fill"
                                                         image={produto.imagemProduto.gatsbyImageData}
                                                         alt={produto.nomeProduto}/>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">{produto.nomeProduto}</h5>
                                            <Link to={"/produtos/" + produto.urlProduto + "/"} className="btn btn-primary">Ver
                                                Produto</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

const QueryGridProdutos = () => (
    <StaticQuery
        query={graphql`
      query GridProdutosQuery {
        produtos: allContentfulProduto(
            filter: {
              imagemProduto: {description: {ne: null}}, 
              nomeProduto: {ne: null}, 
              urlProduto: {ne: null}}
          ) {
            edges {
              node {
                nomeProduto
                urlProduto
                imagemProduto {
                  gatsbyImageData
                }
              }
            }
          }
        }
    `}
        render={(data) => (
            <GridProdutos data={data}/>
        )}
    />
)

export default QueryGridProdutos